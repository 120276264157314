import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout, Header, PreviewCompatibleImage } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Card, CardContainer } from "../ui";
import { SectionTitle } from "../ui/Texts";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/mains.jpg";

export const DonMoelleOsseusePageTemplate = ({ title, campagnTitle, content, contentComponent, posts }) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <SEO title={title} />
      <Header
        title={title}
        bgImage={bgImage}
        bgPosition="center 40%"
        fallback={linearGradients.donMoelleOsseuse}
      />
      <Wrapper padding="80px 0 40px 0">
        <Container>
          <SectionTitle marginB="2rem">{campagnTitle}</SectionTitle>
          <CardContainer two>
            {posts.map(({ node: post }) => {
              const { id, frontmatter, fields } = post;
              return (
                <Card key={id} title={frontmatter.title} link={fields.slug}>
                  <PreviewCompatibleImage
                    imageInfo={frontmatter.image}
                    style={{ height: "100%", borderRadius: "4px 4px 0 0" }}
                  />
                </Card>
              );
            })}
          </CardContainer>
        </Container>
      </Wrapper>
      <Wrapper padding="40px 0 80px 0">
        <Container>
          <PageContent className="markdown" content={content} />
        </Container>
      </Wrapper>
    </>
  );
};

DonMoelleOsseusePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  campagnTitle: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const DonMoelleOsseusePage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const { markdownRemark: post } = data;
  const { frontmatter } = post;

  return (
    <Layout>
      <DonMoelleOsseusePageTemplate
        title={frontmatter.title}
        campagnTitle={frontmatter.campagnTitle}
        contentComponent={HTMLContent}
        content={post.html}
        posts={posts}
      />
    </Layout>
  );
};

DonMoelleOsseusePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DonMoelleOsseusePage;

export const DonMoelleOsseusePageQuery = graphql`
  query DonMoelleOsseusePage($id: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "don-moelle-osseuse-post" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 650, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        campagnTitle
      }
    }
  }
`;
